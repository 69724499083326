<template>
    <div class="p-3">
        <!-- /.modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <form @submit.prevent="save" class="card">
                <div class="modal-content">
                <div class="modal-header bg-primary">
                    <h5 class="modal-title" id="exampleModalLabel"> <i class="fa fa-plus"></i> Add/Edit Merchant</h5>
                </div>
                <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-6">
                               <div class="form-group" id="div_image" @click="defaultBtnImage()" style="cursor: pointer">
                                    <img :src="form.image" v-if="form.image" width="100%" height="100%" class="img-thumbnail" />
                                    <img src="@assets/no-image.png" v-else width="100%" height="100%" class="img-thumbnail" alt="No Image">
                                    <input type="file" accept="image/x-png,image/jpeg" id="default-btn"  @change="handleImage" :disabled="loading" hidden />
                                    <div class="bg-primary text-center p-2" >
                                       Upload Photo<span class="text-red">*</span>
                                    </div>
                                </div>

                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="recipient-name" class="col-form-label">Merchant Name<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" id="recipient-name" v-model="form.name" :disabled="loading">
                                </div>
                                 <div class="form-group">
                                    <label for="recipient-email" class="col-form-label">Email<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" id="recipient-email" v-model="form.email" :disabled="loading">
                                </div>
                                <div class="form-group">
                                    <label for="recipient-password" class="col-form-label">Password<span class="text-red">*</span></label>
                                    <input type="password" class="form-control" id="recipient-password" v-model="form.password" :disabled="loading">
                                </div>
                                <div class="form-group">
                                    <label for="recipient-phone_number" class="col-form-label">Nomer Telepon<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" id="recipient-phone_number" v-model="form.phone_number" :disabled="loading">
                                </div>
                                <div class="form-group">
                                    <label for="recipient-account_name" class="col-form-label">Nama Rekening<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" id="recipient-account_name" v-model="form.account_name" :disabled="loading">
                                </div>
                                <div class="form-group">
                                    <label for="recipient-account_number" class="col-form-label">Nomer Rekening<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" id="recipient-account_number" v-model="form.account_number" :disabled="loading">
                                </div>
                                <div class="form-group">
                                    <label for="recipient-npwp" class="col-form-label">NPWP<span class="text-red">*</span></label>
                                    <input type="text" class="form-control" id="recipient-npwp" v-model="form.npwp" :disabled="loading">
                                </div>
                                <!-- <div class="form-group">
                                    <label class="text-secondary"><span class="text-red">*</span>Photo max 5mb</label>
                                    <label class="text-secondary"><span class="text-red">*</span>File Photo JPEG, JPG, and PNG</label>
                                </div> -->
                            </div>
                        </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                     <button type="submit" class="btn btn-primary" :disabled="loading">
                        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                        {{ loading ? 'Saving ...' : 'Save' }}
                    </button>
                </div>
                </div>
                </form>
            </div>
        </div>

       <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                 <div class="col-sm-6">
                    
                    <div class="row"  style="align-items: center;" v-if="merchant">
                        <img  :src="merchant.image" style=" width: 150px; height: 150px; " />
                        <div class="col ml-2" >
                          <p class="label1" >{{merchant.name}}</p>
                          <p class="label2"  >ID : {{merchant.id}}</p>
                        </div>
                    </div>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right ">
                        <li class="ml-3"><button type="button" class="btn btn-block btn-outline-primary btn-lg p-3"  @click="showModal">  Edit </button></li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
      
        <data-tables-master-data :config="config" @click="clickRow" class="container-fluid" id="sampleTable"></data-tables-master-data>
                
    </div>
</template>


<script>
const {console} = window
import axios from "axios"
import DataTablesMasterData from '@components/DataTablesMasterData.vue'
export default {
     components: {
        DataTablesMasterData
    },

    data() {
        return {
            active: false,
            errorDialog: null,
            errorText: '',
            uploadFieldName: 'file',
            error: undefined,
            loading: false,
            text_validation : null,
            maxSize: 1024,
            merchant:null,
            form : {
                id: null,
                image: "",
                name:"",
                email:"",
                password:"",
                account_name:"",
                account_number:"",
                phone_number:"",
                npwp:""

            }, 
            disabled: false,
            
            config: {
                columns: [
                {
                    title: 'Vending Id',
                    data: 'vending_id'

                },
                {
                    title: 'Nama Vending',
                    data: 'name'
                }, {
                    title: 'Alamat',
                    data: 'address'
                },{
                    title: 'Action',
                    data: 'id',
                    render: (val) => {
                        return '<div @click="showModal"> <span class="label label-info">Details</span></div>'
                    }
                    
                }],
                url:this.Api.base + '/vending?filters=[["merchant.id","=","'+this.$route.params.id+'"]]',
                order: [[0, "desc"]],
            }
        }
    },
    mounted() {
        this.getDataMerchant()
    },
    props: {
      value: Object,
    },
    methods: {
        add() {
        },
        onClick(row) {
        },
        clickRow(row) {
            if(row.data()){
                //this.$router.push({name: 'AssignVending', params: {id: row.data().id}})       
                this.$router.push({ 
                    name: 'AssignVendingDinamis', 
                    query: {vendingId: row.data().id},
                    params: { id:  row.data().id, row:  row.data().row_slot ?  row.data().row_slot : 5, col:  row.data().col_slot ?  row.data().col_slot : 8} })          
            }
        },
        showModal() {
          $("#exampleModal").modal("show");
        },
        defaultBtnImage(){
            const defaultbtn = document.querySelector("#default-btn");
            defaultbtn.click();

        },
        handleImage(e) {
            const selectedImage = e.target.files[0];
            if(selectedImage.type === "image/png" || selectedImage.type === "image/jpeg") {
              if(selectedImage.size <= 1024 * 1024) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.form.image = e.target.result;
                }
                reader.readAsDataURL(selectedImage);
              } else {
                alert('File too big (> 1MB)');
              }
            } else {
              alert('File is not supported')
            }
        },
        async getDataMerchant(){
            if (this.$route.params.id) {
                this.Api.get(`/merchant/${this.$route.params.id}`).then(res =>{
                    this.merchant = res.data;
                    this.form = res.data
                })
                .catch((e) =>{
                    console.log(e);
                })
            }
        },
        save() {
            this.loading = true
            // if(!this.validationPassword()){
            //   this.loading = false
            //   return;
            // }
            this.Api.put(`/merchant/${this.$route.params.id}`, this.form).then(res => {
                this.getDataMerchant()
                $('#exampleModal').modal('hide');
            }).catch((e)  => {
              this.loading = false
              this.error = e.response.data.message
              this.text_validation = "Submit Failed";             
            })
        },
        refresh(){
            this.config = { 
                url: this.Api.base + '/merchant'
            }
        }
        



     

    },
}
</script>
<style>
    .box1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 12px 24px;
        gap: 8px;
        width: 360px;
        height: 190px;
        left: 64px;
        top: 132px;
        background: #FFFFFF;
        border-radius: 12px; 
    }
    .label1{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      color: #0A0A0A;
    }
    .label2{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #404040;
    }
    .label3{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #404040;
    }
    .label4{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #32434A;

    }
    .balel5{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      color: #000000;
    }
    .borderDiv{
      border: 1px solid #C2C2C2;
    }
    .notActive{
        color : red;
    }
</style>
